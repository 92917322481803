import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
 
  return (
    <><div id="home" className="">
      <img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
       <Navbar/>
      </div>
   
    <div className="md:p-20  mt-20">
    
<div className="md:ps-5 ">













<div className="md:flex     text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl ps-5 ">TESTIMONIALS</h1></div>

</div>
</div>

<div className="pt-10 mb-20   text-white ">

  <img src={require("../assets/Testimonials/1.png")} className="object-fill w-[500px] h-[300px] md:h-[500px] md:w-[100%] mt-5"></img>

  <img src={require("../assets/Testimonials/2.png")} className="object-fill w-[500px] h-[300px] md:h-[500px] md:w-[100%] mt-5"></img>

  <img src={require("../assets/Testimonials/3.png")} className="object-fill w-[500px] h-[300px] md:h-[500px] md:w-[100%] mt-5"></img>

  <img src={require("../assets/Testimonials/4.png")} className="object-fill w-[500px] h-[300px] md:h-[500px] md:w-[100%] mt-5"></img>

  <img src={require("../assets/Testimonials/5.png")} className="object-fill w-[500px] h-[300px] md:h-[500px] md:w-[100%] mt-5"></img>

  <img src={require("../assets/Testimonials/6.png")} className="object-fill w-[500px] h-[300px] md:h-[500px] md:w-[100%] mt-5"></img>

  <img src={require("../assets/Testimonials/7.png")} className="object-fill w-[500px] h-[300px] md:h-[500px] md:w-[100%] mt-5"></img>

  <img src={require("../assets/Testimonials/8.png")} className="object-fill w-[500px] h-[300px] md:h-[500px] md:w-[100%] mt-5"></img>


</div>














<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>

</div>

     <div>
<Footer/>
     </div>
    </>
  );
}
