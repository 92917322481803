import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
 
  return (
    <><div id="home" className="">
      <img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
       <Navbar/>
      </div>
      <Swiper
        navigation
        modules={[Navigation, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop={true}
        className="mySwiper"
      >
        {[1, 2, 3].map((num) => (
          <SwiperSlide key={num} className="relative">
            {/* Overlay */}
            <div className="absolute inset-0  bg-opacity-40"></div>

            {/* Slide Image */}
            <img
              className="h-screen w-full object-cover"
              src={require(`../assets/${num}.jpg`)}
              alt={`Slide ${num}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    <div className="md:p-10">
    <div className="md:flex justify-start  text-white">
    
<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-5xl mt-20 m-10">ABOUT US</h1></div>
 <div className="mt-10 md:ms-[40%] ps-10"><img src={require("../assets/logo40.png")} height={300}width={300}></img></div>
</div>
</div><div>
<div className="md:ps-20 md:pe-20 ps-10">
<p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:16,color:"#999999"}}>
<br/><br/>
DGP+ is a group of companies established in 1984, which provides a one window approach to all your design and construction related needs.

DGP+ provides all-inclusive services including Architecture, Interiors, Landscape, Engineering, Construction, Materials and Property Development.

Our vast experience includes the following building typologies:
<br/><br/>
{/* <Lottie height={40}width={40}  animationData={Animation} /> */}

  </p>
  <ul style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14,color:"#999999",fontWeight:'bold'}}>
<li className="font-bold">* Hotels & hospitality</li>
<li className="font-bold">* Educational facilities</li>
<li className="font-bold">* Urban planning initiatives</li>
<li className="font-bold">* Restaurants and office interiors</li>
<li className="font-bold">* Health institutions and hospitals</li>
<li className="font-bold">* Industrial buildings and factories</li>
<li className="font-bold">* Residential and commercial projects</li>
<li className="font-bold">* Social institutions and other charities</li>
<li className="font-bold">* Luxury apartment complexes / buildings</li>
<li className="font-bold">* Vacation homes, beach huts and farmhouses</li>
</ul>









<div className="md:flex    mt-20 text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl ">OUR SERVICES</h1></div>

</div>
<div className="md:flex items-center justify-between mt-20">
<div className="md:flex justify-center items-center">

  <div>
  <img src={require("../assets/footer/Design.JPG")} height={100}width={100}></img>
  
  <div>
  <h3 style={{color:'white',fontFamily:"Oswald",letterSpacing:8 ,marginTop:10,borderBottomWidth:'1px',borderBottomColor:'white'}}>DESIGN</h3>

  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Architecture</h4>
  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Interiors</h4>
  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Landscape</h4>
  </div>
  </div>
</div>
<div className="md:flex justify-center items-center md:ms-5">

  <div>
  <img src={require("../assets/footer/Construction.JPG")} height={100}width={100}></img>
  
  <div>
  <h3 style={{color:'white',fontFamily:"Oswald",letterSpacing:8 ,marginTop:10,borderBottomWidth:'1px',borderBottomColor:'white'}}>CONSTRUCTION</h3>

  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Construction Management</h4>
  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>General Contracting</h4>
  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Engineering</h4>

  </div>
  </div>
</div>
<div className="md:flex justify-center items-center">

  <div>
  <img src={require("../assets/footer/Materials.JPG")} height={100}width={100}></img>
  
  <div>
  <h3 style={{color:'white',fontFamily:"Oswald",letterSpacing:8 ,marginTop:10,borderBottomWidth:'1px',borderBottomColor:'white'}}>MATERIALS</h3>

  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Woodworks</h4>
  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Stoneworks</h4>
  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Exports</h4>
  </div>
  </div>
</div>
<div className="md:flex justify-center items-center">

  <div >
  <img src={require("../assets/footer/Properties.JPG")} height={100}width={100}></img>
  
  <div>
  <h3 style={{color:'white',fontFamily:"Oswald",letterSpacing:8 ,marginTop:10,borderBottomWidth:'1px',borderBottomColor:'white'}}>PROPERTIES</h3>

  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Property Development</h4>
  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Real Estate Consultancy</h4>
  <h4 style={{fontSize:'14px',fontFamily:"Oswald",letterSpacing:3 ,color:'#b8b8b8',marginTop:5}}>Property Evaluations</h4>
  </div>
  </div>
</div>
</div>
















<div className="md:flex    mt-20 text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-20">OUR PORTFOLIO</h1></div>

</div>


<div className="md:flex md:ps-20  justify-around text-white ">
<Link to="/residential">
<div className="mt-10">
  <img className="w-[300px] h-[300px] md:w-[500px]" src={require("../assets/project1.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[300px] md:w-[500px]"><p className="text-center">Residential</p></div>
</div>
</Link>
<Link to="/commercial">
<div className="mt-10">
  <img className="w-[300px] h-[300px] md:w-[500px]" src={require("../assets/project2.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[300px] md:w-[500px]"><p className="text-center">Commercial</p></div></div>
  </Link>


</div>


<section id="facts"  className="wow fadeIn">
      <div className="container text-white">

        <header className="section-header">
        <div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-20 ">FACTS</h1></div>
        <p style={{fontFamily:"Oswald",letterSpacing:3}}>Here are some figures</p>
        </header>

        <div className="md:flex mt-20 justify-around row counters">

  				<div className="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up"><p className="text-4xl">274</p></span>
            <p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:18,marginTop:5}}>Clients</p>
  				</div>

          <div className="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up"><p className="text-4xl">421</p></span>
            <p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:18,marginTop:5}}>Projects</p>
  				</div>

          <div className="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up"><p className="text-4xl">1,364</p></span>
            <p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:18,marginTop:5}}>Hours Of Support</p>
  				</div>

          <div className="col-lg-3 col-6 text-center">
            <span data-toggle="counter-up"><p className="text-4xl">18</p></span>
            <p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:18,marginTop:5}}>Hard Workers</p>
  				</div>

  			</div>

        <div className="facts-img">
          <img src="img/facts-img.png" alt="" className="img-fluid"/>
        </div>

      </div>
    </section>

<div className="md:flex    text-white">

<div className="mt-20"><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-20 mb-10">TESTIMONIALS</h1></div>

</div>



<div className="md:flex  mb-20 pb-20 justify-evenly text-white ">

<div style={{fontFamily:"Oswald",letterSpacing:3,marginTop:10,backgroundColor:"#323232",fontSize:14,padding:20,height:400,width:300}}className="  ">
        <p style={{color:'#99998c'}}>
        “ We had a great time with DGP, and the house building project was not just a construction project but a delightful experience for the family and DGP team was equally involved and excited. This was our second project with DGP and would love to select DGP again for our next project. The architecture/drawing aspect is especially the key strength of DGP led by one and only Samar Ali Khan. ”
        </p>
        <p className="text-white p-10 text-center justify-center items-center" style={{fontFamily:"Oswald",letterSpacing:3}}>Sharjeel Shahid</p>
</div>
<div style={{fontFamily:"Oswald",letterSpacing:3,marginTop:10,backgroundColor:"#323232",fontSize:14,padding:20,height:400,width:300}}className="  ">
        <p style={{color:'#99998c'}}>
        “ My sincere gratitude for the exceptional services your team provided me. Your professionalism, expertise, and attention to detail have been invaluable throughout our project. Your firm has truly delivered outstanding value, and I’m thrilled with the results. I look forward to recommending your firm to others and collaborating with you on future projects.”
        </p>
        <p className="text-white p-10 text-center justify-center items-center" style={{fontFamily:"Oswald",letterSpacing:3}}>Farah Waseem</p>
</div>
<div style={{fontFamily:"Oswald",letterSpacing:3,marginTop:10,backgroundColor:"#323232",fontSize:14,padding:20,height:400,width:300}}className="  ">
        <p style={{color:'#99998c'}}>
        “ After hearing from our friends about architects who rarely visit their projects, I am incredibly grateful to have chosen DGP for this important task. SAK and his team maintained a natural and organic approach, balancing functionality and aesthetics. I especially enjoyed working with Rafi. If I ever build another house, I would choose DGP without hesitation. ”
        </p>
        <p className="text-white p-10 text-center justify-center items-center" style={{fontFamily:"Oswald",letterSpacing:3}}>Nasrullah Khan</p>
</div>

</div>







<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>

</div>
</div>
     <div>
<Footer/>
     </div>
    </>
  );
}
