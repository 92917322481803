import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Menu, X, ChevronDown } from "lucide-react";

export default function Home() {
  return (
    <>
      <footer
        className={`w-full  transition-all duration-300 
         bg-primary shadow-md`}
      >
        <div
          style={{ backgroundColor: "#505050", paddingTop: 2, width: "100%" }}
        />
        <div className="p-2 md:ml-20  md:space-x-5 space-x-3 flex  text-white">
          <img
            src={require("../assets/footer/DGP General.png")}
            height={60}
            width={60}
          ></img>
          <img
            src={require("../assets/footer/Design.JPG")}
            height={60}
            width={60}
          ></img>
          <img
            src={require("../assets/footer/Construction.JPG")}
            height={60}
            width={60}
          ></img>
          <img
            src={require("../assets/footer/Materials.JPG")}
            height={60}
            width={60}
          ></img>
          <img
            src={require("../assets/footer/Properties.JPG")}
            height={60}
            width={60}
          ></img>
        </div>

        <div
          style={{ backgroundColor: "#505050", paddingTop: 2, width: "100%" }}
        />
        <div className="p-10 md:flex justify-between text-white">
          <div className="m-10 ">
            <h3 style={{ fontFamily: "Oswald", letterSpacing: 3 ,fontWeight:'bold'}}>Phone</h3>
            <p style={{ fontFamily: "Oswald", letterSpacing: 3 }}>
              021 35875373 / 4
            </p>
          </div>
          <div className="m-10">
            <h3 style={{ fontFamily: "Oswald", letterSpacing: 3 ,fontWeight:'bold'}}>Email</h3>
            <p style={{ fontFamily: "Oswald", letterSpacing: 3 }}>
              mail@dgp.com.pk
            </p>
          </div>
          <div className="m-10">
            <h3 style={{ fontFamily: "Oswald", letterSpacing: 3 ,fontWeight:'bold'}}>Address</h3>
            <p style={{ fontFamily: "Oswald", letterSpacing: 3 }}>
              E-23 2nd Zamzama Commercial Lane, DHA-5, Karachi
            </p>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#505050", paddingTop: 2, width: "100%" }}
        />
        <div className="p-2 md:flex justify-around items-center text-center align-middle text-white">
          <div
            style={{ fontFamily: "Oswald", letterSpacing: 3 }}
            className="md:flex mt-5"
          >
            <p>Copyright@dgp.com.pk</p>
          </div>
          <div className="md:flex mt-5">
            <a href="https://www.facebook.com/DGP.DesignGroupPractice/">
              <i
                className="fa fa-facebook text-xl mr-5  text-gray-300"
                aria-hidden="true"
              ></i>
            </a>
            <a href="https://www.instagram.com/designgrouppractice/">
              <i
                className="fa fa-instagram text-xl  mr-5  text-gray-300"
                aria-hidden="true"
              ></i>
            </a>
            <a href="https://www.linkedin.com/company/dgp-pk/posts/?feedView=all">
              <i
                className="fa fa-linkedin text-xl mr-5  text-gray-300"
                aria-hidden="true"
              ></i>
            </a>
            
          </div>
          <div
            
            className="md:flex mt-5"
          >
            <p style={{ fontFamily: "Oswald", letterSpacing: 3 }}>Terms & Conditions</p>
          </div>
        </div>

        <div
          className="mt-5"
          style={{ backgroundColor: "#505050", paddingTop: 2, width: "100%" }}
        />
      </footer>
    </>
  );
}
