import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
import axios from "axios";
export default function Home() {
  const [isOpen, setIsOpen] = useState(false);

  
  const [scrolling, setScrolling] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", subject: "", message: "" });



  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setIsOpen(true); // Open modal on page load
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://dgp.com.pk/laravel/public/api/sendform", formData);
      alert("Form submitted successfully!");
      setIsOpen(false);
    } catch (error) {
      alert("Error submitting form");
    }
  };





  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
<div>
<img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
<div className="relative h-full w-full">
 

      <Navbar/>
      

      <div className="p-10">
  
<div className="md:flex  mt-20   text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl md:ps-20 ">CONTACT US</h1></div>
<div className=" md:ms-[40%] p-10 "><img src={require("../assets/logo.png")} height={300}width={300}></img></div>
</div>
</div><div>
<div className="md:ps-20 md:pe-20 ps-10  mb-20">
<p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:16,color:"#999999"}}>
<br/><br/>
DESIGN GROUP PRACTICE
<br/><br/>
{/* <Lottie height={40}width={40}  animationData={Animation} /> */}

<h4>Contact Details</h4>
  </p>
<div className="md:flex justify-between items-center">
<ul style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14,color:"#999999",fontWeight:'bold',padding:20}}>
<li className="font-bold text-xl"><a href="https://www.facebook.com/DGP.DesignGroupPractice/">Facebook</a></li>
<li className="font-bold text-xl"><a href="https://www.instagram.com/designgrouppractice/">Instagram</a></li>
<li className="font-bold text-xl"><a href="https://www.linkedin.com/company/dgp-pk/posts/?feedView=all">LinkedIn</a></li>

</ul>

<ul style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14,color:"#999999",fontWeight:'bold',padding:20}}>

<li className="font-bold text-xl"><b>Phone: </b><a href="tel:02135875373">021 35875373 / 4</a></li>
<li className="font-bold text-xl"><b>Email: </b><a href="mailto:mail@dgp.com.pk">mail@dgp.com.pk</a></li>
<li className="font-bold text-xl"><b>Address: </b><a href="https://maps.app.goo.gl/VyRrdeq95geoEJHg6" target="_blank">E-23 2nd Zamzama Commercial Lane, DHA-5, Karachi</a> </li>

</ul>
</div>
</div></div>
      </div>
      <div className="md:p-5 ">




<div>{isOpen && (
        <div style={{zIndex:1000}} className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="relative bg-[#606060] p-6 rounded-lg shadow-lg w-96 mt-10 " >
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-3 right-3 text-white hover:text-yellow-400 text-5xl"
            >
              &times;
            </button>
            <h2 className="text-xl font-bold mb-4 text-white"style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}}>Contact Us</h2>
            <p className="text-white"style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}}>If you have any Questions or Queries , Please fill the form below</p>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm mt-5 text-white"style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}}>Name</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} className="mt-1 block w-full p-2 border border-gray-300 rounded" required />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-white"style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}}>Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} className="mt-1 block w-full p-2 border border-gray-300 rounded" required />
              </div>
              <div className="mb-4">
                <label className="block text-sm text-white"style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}}>Departments</label>
                <select  name="subject" value={formData.subject} onChange={handleChange} className="mt-1 block w-full p-2 border text-black border-gray-300 rounded" required>
                  <option style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}} value="">Select an option</option>
                  <option style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}} value="Design">Design</option>
                  <option style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}} value="Construction">Construction</option>
                  <option style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}} value="Materials">Materials</option>
                  <option style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}} value="Properties">Properties</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm text-white"style={{fontFamily:"Oswald",letterSpacing:3,fontSize:14}}>Message</label>
                <textarea name="message" value={formData.message} onChange={handleChange} className="mt-1 block w-full p-2 border border-gray-300 rounded" rows="3" required></textarea>
              </div>
              <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600">Submit</button>
            </form>
          </div>
        </div>
      )}</div>















<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>


</div>
     <div>
<Footer/>
     </div>
    </div>
  );
}
