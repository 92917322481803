import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
 
  return (
    <><div id="home" className="">
      <img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
       <Navbar/>
      </div>
   
    <div className="md:p-20 p-10">
    
<div className="md:ps-20 ">













<div className="md:flex     text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-20">INTERIORS</h1></div>

</div>

<div className="md:flex  pt-10 justify-around text-white ">
<Link to="/testkitchen">
<div className="mt-20 md:mt-5 w-[300px] h-[300px] md:w-[500px]">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/2. Commercial/2. Interiors/1. Test Kitchen/DGP_0002  (Test Kitchen).jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Test Kitchen</p></div>
</div>
</Link>
<Link to="/elahee">
<div className="mt-20 md:mt-5 w-[300px] h-[300px] md:w-[500px]">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/2. Commercial/2. Interiors/2. Elahee Buksh & Co/1.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Elahee Buksh & Co</p></div></div>
  </Link>
</div>
<div className="md:flex  pt-10 justify-around text-white ">
<Link to="/okra">
<div className="mt-20 md:mt-5 w-[300px] h-[300px] md:w-[500px]">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/2. Commercial/2. Interiors/3. Okra/Picture193.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Okra</p></div>
</div>
</Link>
<Link to="/hubleather">
<div className="mt-20 md:mt-5 w-[300px] h-[300px] md:w-[500px]">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/2. Commercial/2. Interiors/4. Hub Leather/Picture155.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Hub Leather</p></div></div>
  </Link>
</div>
<div className="md:flex  pt-10 justify-around text-white ">
<Link to="/sungi">
<div className="mt-20 md:mt-5 w-[300px] h-[300px] md:w-[500px]">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/2. Commercial/2. Interiors/5. Sungi/Picture221.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Sungi</p></div>
</div>
</Link>
<Link to="/livingfloors">
<div className="mt-20 md:mt-5 w-[300px] h-[300px] md:w-[500px]">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/2. Commercial/2. Interiors/6. Living Floors/Picture175.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Living Floors</p></div></div>
  </Link>
</div>














<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>

</div>
</div>
     <div>
<Footer/>
     </div>
    </>
  );
}
