import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
<div>
<img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
<div className="relative h-full w-full">
 

      <Navbar/>
      

      <div className="md:p-10">
    <div className="md:flex   text-white">
    
<div className="mt-10"><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-[80px] m-10">FLOORING</h1></div>
</div>
</div><div>
</div>
      </div>
      <div className="md:p-5 ">

















<div className="md:flex p-1 space-x-1 justify-center text-white ">

<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/0635.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/0706-2.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
</div><div className="md:flex p-1 space-x-1 justify-center text-white ">

<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/0711-1.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/0742-1.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/0816-1.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/0830.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1130-1.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1130.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1302.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1318.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1509-3.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1509-4.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1521.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1608-1.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">


<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/1701.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/9620-2.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">

<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/9923.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/9928-6.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>
<div className="md:flex p-1 space-x-1 justify-center text-white ">

<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/PD-3.jpg")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>
<div>
  <img src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/0406-1.JPG")} className="object-cover m-1 w-[400px] h-[400px] md:w-[500px] " style={{borderWidth:10,borderColor:"#999"}} ></img>
</div>

</div>





<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>


</div>
     <div>
<Footer/>
     </div>
    </div>
  );
}
