import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
<div >
<img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
<div className="relative h-full w-full">
 

      <Navbar/>
      

      <div className="md:flex  md:p-20 p-10  text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-20">MATERIALS</h1></div>

</div>

<div className="md:flex justify-evenly">


<div className=" md:w-[60%] p-5">
<p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:17,color:"white"}}>
DGP+ Materials was established through the realization that there is a substantial need amongst the architectural and building community to provide material consultancy services and the provision of vetted suppliers for each stage of the building process. With a consolidated panel of vendors as well as a host of in-house services, DGP+ Materials is a one stop shop for any inquiry into the building material industry.
Our specialties include:
 <br/><br/>


{/* <Lottie height={40}width={40}  animationData={Animation} /> */}

  </p>
  <p style={{fontFamily:"Oswald",letterSpacing:3,fontSize:15,color:"white", fontWeight:"bold"}}>
  
*  Woodworks <br/><br/>

*  Stoneworks <br/><br/>

*  Exports <br/><br/>




</p>
  </div>
<div className="bg-[#222] h-[300px] m-10  w-[300px] p-10 ">
    <h4 style={{fontFamily:"Oswald",letterSpacing:3,fontSize:18,fontWeight:'bold',color:"gray"}}>ALL SERVICES</h4>
  
    <div style={{display:'flex',flexDirection:"column"}}>
    <Link to="/design" style={{fontFamily:"Oswald",letterSpacing:3,fontSize:16,color:"gray",padding:10,marginTop:10}}>DESIGN</Link>
    <Link to="/construction" style={{fontFamily:"Oswald",letterSpacing:3,fontSize:16,color:"gray",padding:10}}>CONSTRUCTION</Link>
    <Link to="/materials" style={{fontFamily:"Oswald",letterSpacing:3,fontSize:16,color:"gray",padding:10,borderBottomColor:"gray",borderBottomWidth:0.5}}>MATERIALS</Link>
    <Link to="/properties" style={{fontFamily:"Oswald",letterSpacing:3,fontSize:16,color:"gray",padding:10}}>PROPERTIES</Link>
    </div>
</div>
</div>




      </div>
      <div className="md:p-2 ">

















<div className="md:flex p-2 justify-evenly text-white ">

<div >
  <img src={require("../assets/Services/Materials/001.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>
<div >
  <img src={require("../assets/Services/Materials/008.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>
</div><div className="md:flex p-2 justify-evenly text-white ">
<div >
  <img src={require("../assets/Services/Materials/13.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>

<div >
  <img src={require("../assets/Services/Materials/16.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>

</div>
<div className="md:flex p-2 justify-evenly text-white ">


<div >
  <img src={require("../assets/Services/Materials/21.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>
<div >
  <img src={require("../assets/Services/Materials/22.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>

</div>
<div className="md:flex p-2 justify-evenly text-white ">


<div >
  <img src={require("../assets/Services/Materials/6 (2).jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>
<div >
  <img src={require("../assets/Services/Materials/IN-001.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>

</div>
<div className="md:flex p-2 justify-evenly text-white ">


<div >
  <img src={require("../assets/Services/Materials/IN-022.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>
<div >
  <img src={require("../assets/Services/Materials/IN-027.jpg")} className="object-cover m-2 md:w-[600px] h-[400px] w-[400px]" style={{borderColor:'#999',borderWidth:10}}></img>
</div>

</div>







<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>


</div>
     <div >
<Footer/>
     </div>
    </div>
  );
}
