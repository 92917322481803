import React from 'react'
//require('../css/sidebars.css');


export default function nopage() {
  return (
    <div style={{backgroundColor:'black',justifyContent:'center',alignItems:'center'}}>
        Page Not Found
    </div>
  )
}
