import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Menu, X ,ChevronDown} from "lucide-react";
import Animation from  "../assets/loading.json"
import Navbar from './navbar'
import Footer from './footer'
import Lottie from "lottie-react";
export default function Home() {
 
  return (
    <><div id="home" className="">
      <img
  src={require("../assets/DGP+ Grid.png")}
  alt="Background"
  className="fixed inset-0 w-full h-full object-cover z-[-2]"
/>
<div className="inset-0 bg-gradient-to-b fixed object-cover from-black/50 via-black/70 to-black z-[-1]"></div>
       <Navbar/>
      </div>
   
    <div className="md:p-20 p-10">
    
<div className="md:ps-20 ">













<div className="md:flex     text-white">

<div><h1  style={{fontFamily:"Oswald",letterSpacing:8}} className="text-4xl mt-20">ELEMENTS</h1></div>

</div>

<div className="md:flex  pt-10 justify-around text-white ">
<Link to="/bathrooms">
<div className="md:mt-5 w-[300px] h-[300px] md:w-[500px] mt-20">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/1. Residential/2. Elements/Bathrooms/1130-3.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Bathrooms</p></div>
</div>
</Link>
<Link to="/courtyards">
<div className="md:mt-5 w-[300px] h-[300px] md:w-[500px] mt-20">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/1. Residential/2. Elements/Courtyards/1130-1.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Courtyards</p></div></div>
  </Link>
</div>
<div className="md:flex  pt-10 justify-around text-white ">
<Link to="/flooring">
<div className="md:mt-5 w-[300px] h-[300px] md:w-[500px] mt-20">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/1. Residential/2. Elements/Flooring/0711-1.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Flooring</p></div>
</div>
</Link>
<Link to="/gates">
<div className="md:mt-5 w-[300px] h-[300px] md:w-[500px] mt-20">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/1. Residential/2. Elements/Gates/0830-5.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Gates</p></div></div>
  </Link>
</div>
<div className="md:flex  pt-10 justify-around text-white ">
<Link to="/pools">
<div className="md:mt-5 w-[300px] h-[300px] md:w-[500px] mt-20">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/1. Residential/2. Elements/Pools/0635-4.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Pools</p></div>
</div>
</Link>
<Link to="/skylights">
<div className="md:mt-5 w-[300px] h-[300px] md:w-[500px] mt-20">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/1. Residential/2. Elements/Skylights/0128-6.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Skylights</p></div></div>
  </Link>
</div>
<div className="md:flex  pt-10 justify-around text-white ">
<Link to="/staircases">
<div className="md:mt-5 w-[300px] h-[300px] md:w-[500px] mt-20">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/1. Residential/2. Elements/Staircases/0816-1.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Staircases</p></div>
</div>
</Link>
<Link to="/wallfinishes">
<div className="md:mt-5 w-[300px] h-[300px] md:w-[500px] mt-20">
  <img className="w-[100%] h-[100%] md:w-[100%]" src={require("../assets/Portfolio/1. Residential/2. Elements/Wall Finishes/0303-3.jpg")} ></img>
  <div className="bg-stone-800 p-3 w-[100%] h-[50px] md:w-[100%]"><p className="text-center">Wall Finishes</p></div></div>
  </Link>
</div>














<a href="#home"><div className="fixed right-10 bottom-10 flex justify-center items-center"><i className="fa fa-chevron-up flex justify-center items-center" style={{backgroundColor:"#505050",color:'white',borderRadius:100,height:40,width:40}}></i></div>
</a>

</div>
</div>
     <div>
<Footer/>
     </div>
    </>
  );
}
