import { Link } from "react-router-dom";
import React,{useState,useEffect} from "react";
import { Menu, X ,ChevronDown} from "lucide-react";

export default function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
    <nav
        className={`md:mt-2 fixed w-full top-0 z-50 transition-all duration-300  ${
          scrolling ? "bg-primary shadow-md opacity-85" : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-14">
            {/* Logo */}
            <Link to="/" className="text-2xl font-bold text-white">
              <img src={require("../assets/logo.png")} height={120} width={120} />
            </Link>

            {/* Desktop Menu */}
            <div className="hidden md:flex space-x-6">
              <Link to="/" className="text-white hover:text-gray-300 transition"style={{fontFamily:"Oswald",letterSpacing:3}}>HOME</Link>
              <Link to="/about" className="text-white hover:text-gray-300 transition"style={{fontFamily:"Oswald",letterSpacing:3}}>ABOUT</Link>
              <Link to="/team" className="text-white hover:text-gray-300 transition"style={{fontFamily:"Oswald",letterSpacing:3}}>TEAM</Link>

              {/* Services Dropdown */}
              <div className="relative group">
                <button className="flex items-center text-white hover:text-gray-300 transition"style={{fontFamily:"Oswald",letterSpacing:3}}>
                  SERVICES <ChevronDown size={18} className="ml-1" />
                </button>
                <div className="absolute left-0 p-5 hidden group-hover:block  text-white shadow-lg rounded-md w-50" style={{backgroundColor:"#272727"}}>
                  <Link to="/design" className="block px-4 py-2 hover:bg-gray-700">Design</Link>
                  <Link to="/construction" className="block px-4 py-2 hover:bg-gray-700">Construction</Link>
                  <Link to="/materials" className="block px-4 py-2 hover:bg-gray-700">Materials</Link>
                  <Link to="/properties" className="block px-4 py-2 hover:bg-gray-700">Properties</Link>
                </div>
              </div>

              <Link to="/portfolio" className="text-white hover:text-gray-300 transition"style={{fontFamily:"Oswald",letterSpacing:3}}>PORTFOLIO</Link>
              <Link to="/testimonials" className="text-white hover:text-gray-300 transition"style={{fontFamily:"Oswald",letterSpacing:3}}>TESTIMONIALS</Link>
              <Link to="/contactus" className="text-white hover:text-gray-300 transition"style={{fontFamily:"Oswald",letterSpacing:3}}>CONTACT US</Link>
            </div>

            {/* Mobile Menu Button */}
            <button className="md:hidden text-white" onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div className={`md:hidden p-5 justify-center transition-all duration-300 ${isOpen ? "block" : "hidden"}`}>
          <div className="px-4 py-2  shadow-md" style={{backgroundColor:"#272727"}}>
            <Link to="/" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}}  onClick={() => setIsOpen(false)}>Home</Link>
            <Link to="/about" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>About</Link>
            <Link to="/team" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>Team</Link>
            
            {/* Services Dropdown (Mobile) */}
            <button 
              className="block py-2 text-white hover:text-gray-300 flex justify-between w-full" 
              onClick={() => setDropdownOpen(!dropdownOpen)}
              style={{fontFamily:"Oswald",letterSpacing:3}}
            >
              Services <ChevronDown size={18} />
            </button>
            <div className={`pl-4 transition-all duration-300 ${dropdownOpen ? "block" : "hidden"}`}>
              <Link to="/design" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>Design</Link>
              <Link to="/construction" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>Construction</Link>
              <Link to="/materials" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>Materials</Link>
              <Link to="/properties" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>Properties</Link>
            </div>

            <Link to="/portfolio" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>Portfolio</Link>
            <Link to="/testimonials" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>Testimonials</Link>
            <Link to="/contactus" className="block py-2 text-white hover:text-gray-300"style={{fontFamily:"Oswald",letterSpacing:3}} onClick={() => setIsOpen(false)}>Contact</Link>
          </div>
        </div>
      </nav>
    </>
  );
}
